import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { EditorState, convertToRaw } from "draft-js";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  openD: any;
  drawerWidth: any;
  editorState: EditorState;
  ticketCategory: any;
  ticketCategoryData: any;
  ticketCategoryDataSelect: any;
  ticketSubject: string;
  ticketFile: any;
  ticketFullName: string;
  ticketEmail: string;
  ticketPhoneNumber: string;
  ticketConsent: boolean;
  ticketErrors: any;
  customCategorisData:any;
  academicConcern:any;
  academicProgram:any;
  academicProgramAutoCompleteValue:any;
  enrollmentStudentName:any;
  enrollmentPaymentsConcern:any;
  otherConcernsHGPH:any;
  recordsPartnerSchool:any;
  recordsStudentName:any;
  recordsoncerns:any;
  recordsHGPHHubs:any;
  recordsSchoolYear:any;
  shineConcerns:any;
  trainingConcerns:any;
  academicConcernId: string;
  academicProgramId: string;
  academicTestingData: any;
  academicTestingDataSelected: any;
  academicConcernData: any;
  academicProgramData: any;
  ticketsPageInfo: any;
  allTicketsData: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TicketSystemController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiTicketCategoriesCallId: string = "";
  apiCustomCategoriesCallId: string = "";
  apiCreateTicketCallId: string = "";
  apiAllTicketsCallId: string = "";


  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    // Customizable Area Start
    // Customizable Area End


    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: true,
      // Customizable Area Start
      openD: true,
      drawerWidth: 300,
      editorState: EditorState.createEmpty(),
      ticketCategory: { id: "", name: "choose" },
      ticketCategoryData: [],
      ticketCategoryDataSelect: [],
      ticketSubject: "",
      ticketFile: { filenm: new File([""], "") },
      ticketFullName: "",
      ticketEmail: "",
      ticketPhoneNumber: "",
      ticketConsent: false,
      ticketErrors: {},
      customCategorisData:[],
      academicConcern:{id:"", text:""},
      academicProgram:{id:"", text:""},
      academicProgramAutoCompleteValue:'',
      enrollmentStudentName:[],
      enrollmentPaymentsConcern:[],
      otherConcernsHGPH:[],
      recordsPartnerSchool:[],
      recordsStudentName:[],
      recordsoncerns:[],
      recordsHGPHHubs:[],
      recordsSchoolYear:[],
      shineConcerns:[],
      trainingConcerns:[],

      academicConcernId: '',
      academicProgramId: '',
      academicTestingData: [],
      academicTestingDataSelected: [],
      academicConcernData: [],
      academicProgramData: [],
      ticketsPageInfo: {},
      allTicketsData: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

    // ---------------------  get ticket categories response start -----------------------
    if (this.apiTicketCategoriesCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse) {
        this.setState({ ticketCategoryData: apiResponse })
        let data: any = [];
        this.state.ticketCategoryData?.map((item: any) => {
          data.push({
            id: item?.id,
            name: item?.name,
          })
        })
        this.setState({ ticketCategoryDataSelect: data })
      }
      else {
        console.log('ticket categories fetching error', apiResponse?.errors)
      }
    }
    // ---------------------  get ticket categories response end -----------------------

    // ---------------------  custom categories response start -----------------------

  if (this.apiCustomCategoriesCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.setState({customCategorisData:apiResponse})

    if (!this.state.customCategorisData.errors) {
    // --------------------------- Academics & Testing ---------------------------
      if(this.state.customCategorisData.category_id == this.state.ticketCategory.id  && 
        this.state.ticketCategory.name == "Academics & Testing"){
 
        this.state.customCategorisData.custom_fields.map((item:any)=>{
      
          item.name == "Academics and Testing Concern" && item.choices.map((choice:any)=>{
            this.state.academicConcernData.push({
              text:choice.text,
              id:choice.id,
            })
          })

          item.name == "Academics and Testing Concern" &&
            this.state.academicConcernData.push({
              customCatergoryName:item.name,
              customId:item.id,
            })
         

          item.name == "HGPH Program" && item.choices.map((choice:any)=>{
            this.state.academicProgramData.push({
              text:choice.text,
              id:choice.id,
            })
          })

          item.name == "HGPH Program" &&
          this.state.academicProgramData.push({
            customCatergoryName:item.name,
            customId:item.id,
          })

        })
      }
      // --------------------------- Enrollment & Payment ---------------------------
      if(this.state.customCategorisData.category_id == this.state.ticketCategory.id  && 
        this.state.ticketCategory.name == "Enrollment & Payment"){
           this.state.customCategorisData.custom_fields.map((item:any)=>{
      
           item.name == "Name of Student" &&
              this.state.enrollmentStudentName.push({
                customCatergoryName:item.name,
                customId:item.id,
              })
           
  
            item.name == "Enrollment and Payments Concern" && item.choices.map((choice:any)=>{
              this.state.enrollmentPaymentsConcern.push({
                text:choice.text,
                id:choice.id,
              })
            })
  
            item.name == "Enrollment and Payments Concern" &&
            this.state.enrollmentPaymentsConcern.push({
              customCatergoryName:item.name,
              customId:item.id,
            })
  
          })
      }
 // --------------------------- HGPH Other Concerns ---------------------------
      if(this.state.customCategorisData.category_id == this.state.ticketCategory.id  && 
        this.state.ticketCategory.name == "HGPH Other Concerns"){
        console.log('custom categories ID', this.state.customCategorisData.category_id)
        console.log('custom categories FIELDS', this.state.customCategorisData.custom_fields)

        this.state.customCategorisData.custom_fields.map((item:any)=>{
      
           item.name == "Other Concerns (PH)" && item.choices.map((choice:any)=>{
             this.state.otherConcernsHGPH.push({
               text:choice.text,
               id:choice.id,
             })
           })
 
           item.name == "Other Concerns (PH)" &&
           this.state.otherConcernsHGPH.push({
             customCatergoryName:item.name,
             customId:item.id,
           })
 
         })
         console.log("otherConcernsHGPH",this.state.otherConcernsHGPH)
      }

       // --------------------------- Records or Documents ---------------------------
      if(this.state.customCategorisData.category_id == this.state.ticketCategory.id  && 
        this.state.ticketCategory.name == "Records or Documents"){
 
        this.state.customCategorisData.custom_fields.map((item:any)=>{
      
          item.name == "Partner School" && item.choices.map((choice:any)=>{
            this.state.recordsPartnerSchool.push({
              text:choice.text,
              id:choice.id,
            })
          })

          item.name == "Partner School" &&
          this.state.recordsPartnerSchool.push({
            customCatergoryName:item.name,
            customId:item.id,
          })

          item.name == "Name of Student" &&
          this.state.recordsStudentName.push({
            customCatergoryName:item.name,
            customId:item.id,
          })

          item.name == "Records Concerns" && item.choices.map((choice:any)=>{
            this.state.recordsoncerns.push({
              text:choice.text,
              id:choice.id,
            })
          })

          item.name == "Records Concerns" &&
          this.state.recordsoncerns.push({
            customCatergoryName:item.name,
            customId:item.id,
          })

          item.name == "HGPH Hubs" && item.choices.map((choice:any)=>{
            this.state.recordsHGPHHubs.push({
              text:choice.text,
              id:choice.id,
            })
          })

          item.name == "HGPH Hubs" &&
          this.state.recordsHGPHHubs.push({
            customCatergoryName:item.name,
            customId:item.id,
          })

          item.name == "School Year" && item.choices.map((choice:any)=>{
            this.state.recordsSchoolYear.push({
              text:choice.text,
              id:choice.id,
            })
          })

          item.name == "School Year" &&
          this.state.recordsSchoolYear.push({
            customCatergoryName:item.name,
            customId:item.id,
          })

        })
       }

        // --------------------------- Shine or Events---------------------------
      if(this.state.customCategorisData.category_id == this.state.ticketCategory.id  && 
        this.state.ticketCategory.name == "Shine or Events"){

        this.state.customCategorisData.custom_fields.map((item:any)=>{
      
          item.name == "Shine Concerns" && item.choices.map((choice:any)=>{
            this.state.shineConcerns.push({
              text:choice.text,
              id:choice.id,
            })
          })

          item.name == "Shine Concerns" &&
          this.state.shineConcerns.push({
            customCatergoryName:item.name,
            customId:item.id,
          })

        })
      }

       // --------------------------- Training & Engagement ---------------------------
      if(this.state.customCategorisData.category_id == this.state.ticketCategory.id  && 
        this.state.ticketCategory.name == "Training & Engagement"){
        this.state.customCategorisData.custom_fields.map((item:any)=>{
      
          item.name == "Training and Engagement Concerns" && item.choices.map((choice:any)=>{
            this.state.trainingConcerns.push({
              text:choice.text,
              id:choice.id,
            })
          })

          item.name == "Training and Engagement Concerns" &&
          this.state.trainingConcerns.push({
            customCatergoryName:item.name,
            customId:item.id,
          })

        })
      }

     
   
   }

    else {
        console.log('custom categories error', this.state.customCategorisData.errors[0])
      }
  }
  // ---------------------  custom categories response end -----------------------

  // ---------------------  create ticket response start -----------------------
  if (this.apiCreateTicketCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiResponse) {
      console.log('create ticket', apiResponse)
   }
    else {
        console.log('create ticket error', apiResponse?.errors)
      }
  }
  // ---------------------  create ticket response end -----------------------
 
  

    // -----------------------All Tickets start-----------------------------------------------------------
    if (this.apiAllTicketsCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse) {      
        this.setState({ ticketsPageInfo: apiResponse?.page_info })
        this.setState({ allTicketsData: apiResponse?.data })
      }

      else {
        console.log(' All Tickets error', apiResponse?.error)
      }
    }
    // Customizable Area End
  }

    // -----------------------All Tickets end-----------------------------------------------------------
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.getTicketCategories();
    this.getAllTicketsData();


  }
  // -----------------------Ticket categories start-----------------------------------------------------------
  getTicketCategories = () => {
    const header = {
      "Content-Type": configJSON.apicontentType,
      "token": localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiTicketCategoriesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiTicketCategoriesCallUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // -----------------------Ticket categories end-----------------------------------------------------------
  // -----------------------Custom categories start-----------------------------------------------------------
  getCustomCategories = () => {

    const header = {
      "Content-Type": configJSON.apicontentType,
       "token": localStorage.getItem("authToken")
    };
    const httpBody = {
      data: {
        category_id: this.state.ticketCategory.id,
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCustomCategoriesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiCustomCategoriesCallUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

// -----------------------Custom categories end-----------------------------------------------------------
  // -----------------------Custom categories start-----------------------------------------------------------
  createTicket = () => {

    const header = {
      "Content-Type": configJSON.apicontentType,
       "token": localStorage.getItem("authToken")
    };
    const httpBody = {
      ticket: {
            name: this.state.ticketFullName,
            email: this.state.ticketEmail,
            subject: this.state.ticketSubject,
            text: convertToRaw(this.state.editorState.getCurrentContent()).blocks[0].text,
            category: 36,
            // category: this.state.ticketCategory.id,
            ['t-cf-33']: 406,
            ['t-cf-32']: 387
         }
      }
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreateTicketCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiCreateTicketCallUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // -----------------------Custom categories end-----------------------------------------------------------

  // -----------------------All Tickets start-----------------------------------------------------------

  getAllTicketsData = () => {

    const header = {
      "Content-Type": configJSON.contentTypeApiHgFormula,
      "token": localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAllTicketsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allTicketsUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.allTicketsMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  // -----------------------All Tickets end-----------------------------------------------------------


  handleDrawerOpen = () => {
    this.setState({ openD: !this.state?.openD })
  }

  onEditorStateChange = (editorState: EditorState) => {
    this.setState({
      editorState
    });
    this.state.ticketErrors['editorState'] = ""
    console.log("TEST DEBUG", convertToRaw(editorState.getCurrentContent()).blocks[0].text)
  };

  handleChangeTicketCategory = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.state.ticketErrors['ticketCategory'] = "";
    this.setState({
      ticketCategory: {
        ...this.state.ticketCategory,
        name: e.target.value,
        id: this.state.ticketCategoryDataSelect.find((o: any) => o.name == e.target.value)?.id
      }
    }, () => {
      this.getCustomCategories();
    })
  }

   handlerChangeacademicConcern=(e: React.ChangeEvent<HTMLInputElement>,newInputValue:any) => {
    // this.state.ticketErrors['academicConcern'] = "";
    this.setState({
      academicConcern: {
        ...this.state.academicConcern,
         text: newInputValue,
         id: this.state.academicConcernData.find((o: any) => o.text == newInputValue)?.id
      }
    })
  }

  handlerChangeticketConsent=(e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ ticketConsent: e.target.checked })
    this.state.ticketErrors['ticketConsent'] = "";
  }

  handlerChangeticketSubject=(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ ticketSubject: e.target.value })
  handlerChangeticketFullName=(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ ticketFullName: e.target.value })
  handlerChangeticketEmail=(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ ticketEmail: e.target.value })
  handlerChangeticketPhoneNumber=(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ ticketPhoneNumber: e.target.value })
  handleChangeacademicProgramAutoCompleteValue=(newValue:any) => this.setState({academicProgramAutoCompleteValue:newValue})

   hideError = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.state.ticketErrors[event.target.name] = '';
    this.setState({ ticketErrors: this.state.ticketErrors})
  }

  handleCancelTicket = () => {
    this.setState({
      editorState: EditorState.createEmpty(),
      ticketCategory: "choose",
      ticketSubject: "",
      ticketFile: { filenm: new File([""], "") },
      ticketFullName: "",
      ticketEmail: "",
      ticketPhoneNumber: "",
      ticketConsent: false,
      ticketErrors: {},
    })
  }


  handleCreateTicket = () => {
    if (this.ticketValidate()) {
      this.createTicket()
   }
   else {

      this.setState({
        ticketErrors: this.state.ticketErrors
      })
      console.log(this.state.ticketErrors)
    }
  }

  //  =================== ticket validation starts ===========================
  ticketValidate = () => {
    let isFormValid = true;
    const regexName = /^[a-zA-Z.][a-zA-Z. ]*$/;
    const regexEmail = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;

    if (this.state.ticketCategory === 'choose') {
      this.state.ticketErrors['ticketCategory'] = "Required"
      isFormValid = false
    }
    if (this.state.ticketSubject === '') {
      this.state.ticketErrors['ticketSubject'] = "Required"
      isFormValid = false
    }
    if (convertToRaw(this.state.editorState.getCurrentContent()).blocks[0].text == "") {
      this.state.ticketErrors['editorState'] = "Required"
      isFormValid = false
    }
    if (this.state.ticketFullName === '') {
      this.state.ticketErrors['ticketFullName'] = "Required"
      isFormValid = false
    }
    else if (!regexName.test(this.state.ticketFullName)) {
      isFormValid = false;
      this.state.ticketErrors['ticketFullName'] = "Incorrect format - Only alphabets and period are allowed."
    }

    if (this.state.ticketEmail === '') {
      this.state.ticketErrors['ticketEmail'] = "Required"
      isFormValid = false
    }
    else if (!regexEmail.test(this.state.ticketEmail)) {
      isFormValid = false;
      this.state.ticketErrors['ticketEmail'] = "Incorrect Email"
    }

    if (this.state.ticketPhoneNumber === '') {
      this.state.ticketErrors['ticketPhoneNumber'] = "Required"
      isFormValid = false
    }
    if (this.state.ticketConsent === false) {
      this.state.ticketErrors['ticketConsent'] = "Required"
      isFormValid = false
    }

    return isFormValid
  }
  //  =================== ticket validation ends ===========================

  ///file upload function start
  uploadFiles = (file: any, purpose: any) => {
    if (purpose === 'ticketFile') {
      this.setState({ ticketFile: { filenm: file } })
    }
  }
   ///file upload function end


  // Customizable Area End
}
