import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import Menu from '@material-ui/core/Menu';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';



import {
  Box,
  // Customizable Area Start
  Card,
  Button,
  Modal,
  // Customizable Area End
} from "@material-ui/core";
import { logo, avatar, } from '../../blocks/dashboard/src/assets';
import Avatar from '@material-ui/core/Avatar';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FiberManualRecordSharpIcon from '@material-ui/icons/FiberManualRecordSharp';
import { withRouter } from 'react-router-dom'

const drawerWidth = 300;


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    logoutMessage:{
      textAlign:'center',
      margin:'0'
    },
    confirmationMessage:{
      textAlign:'center',
      fontSize:'large',
      color:'rgb(146, 146, 157)',
      fontFamily:"Roboto",
      marginTop:'10px'
    },
    logoutButton: {
      backgroundColor: 'rgb(250, 176, 61)', color: 'white', padding: '5px 15px', fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 600,
      '&:hover': {
        backgroundColor: 'rgb(250, 176, 61)',
        boxShadow: 'none',
      },
    },
    cancelButton: {
      position: "absolute",
      top: "-3%",
      background: "rgb(185, 142, 214)",
      color: "white",
      left: "-10px",
      borderRadius: '20px',
      border: 'none',
      cursor: 'pointer',
      padding: '5px 10px'
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,

      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    
    },
    nested: {
      paddingLeft: theme.spacing(4)
    },
    drawerOpen: {
      width: drawerWidth,
      overflowX: 'hidden',

      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      backgroundColor: " #99cb51",
      borderRadius: "0px 0px 40px 0px",

      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },

    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,

    },
    size: {
      marginTop: '2em',
      [theme.breakpoints.up('xl')]: {
        marginTop: '5em'
      }
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      backgroundColor: "#fff"
    },
    overrides: {

      MuiAppBar: {
        root: {
          backgroundColor: "white"
        }
      },




    }
  }),
);

function MiniDrawer(props: any) {


  const classes = useStyles();
   const theme = useTheme();
  const [open, setOpen] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClickDrop = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDrop = () => {
    setAnchorEl(null);
  };
  const navigateTOHome: any = () => {
    // window.location.assign(window.location.origin + "/DashBoardDayInLifeVideo")
    localStorage.removeItem('hglink')
    localStorage.getItem('complete') === 'true' ? props.navigation.navigate('DashBoardDayInLifeVideo')
      : props.navigation.navigate('DashBoardNonExpandablePage')
  }

  const [subArrayManage, setSubArrayManage] = React.useState("");
  const [subArrayState, setSubArrayState] = React.useState({
    Home: { keystate: false, },
    Overview: { keystate: false, },
    Students: { keystate: false, },
    touch: { keystate: false, },
    FAQs: { keystate: false, },
    Account: { keystate: false, },
  });

  const [routes, setRoutes] = React.useState<any>([{
    routeName: "/DashBoardDayInLifeVideo",
    isActive: false, title: "Home", icon:
      <DashboardOutlinedIcon
      />,
    route: ["DashBoardDayInLifeVideo", "DashBoardNonExpandablePage"], key: "Home"
  },
  {
    routeName: "/Overview",
    isActive: false, title: "Overview", icon: <ViewListOutlinedIcon />, key: "Overview", route: ["Overview"],
    subArray:
      [
        { nav: 'MainDrawer', routeName: 'OrientationVideo', title: 'Orientation', isActive: false },
        { nav: 'MainDrawer', routeName: 'HgFormula', title: 'HG Formula', isActive: false },
        { nav: 'MainDrawer', routeName: 'HgFormula', title: 'Explore Programs', isActive: false },
        { nav: 'MainDrawer', routeName: 'HgFormula', title: 'Info Pack & E-book', isActive: false },
        { nav: 'MainDrawer', routeName: 'HgFormula', title: 'About HG', isActive: false },
      ]
  },

  {
    routeName: "/StudentsList",
    isActive: false, title: "Students", icon: <SchoolOutlinedIcon />, key: 'Students', route: ["StudentsList"]
  },
  {
    routeName: "/no",
    isActive: false, title: "Get in touch", icon: <ChatOutlinedIcon />, key: 'touch', route: ["no"],
    subArray:
      [
        { nav: 'MainDrawer', routeName: 'Chat9', title: 'Chat' },
        { nav: 'MainDrawer', routeName: 'CreateTickets', title: 'Tickets' },
        {
          nav: 'MainDrawer', routeName: 'team', title:
            <Card style={{
              backgroundColor: "#b8b8b8",
              fontFamily: "Poppins",
              fontSize: "12px",
              fontWeight: 500,
              textAlign: "center",
              width: '127px',
              borderRadius: '10px'
            }}>
              <Box style={{ color: "#fafafb" }}>
                <ListItemText primary=
                  {<span style={{
                    fontSize: "12px", fontWeight: 500,
                    color: "white",
                    fontFamily: "Poppins"

                  }} >Meet the team</span>}
                />
              </Box>
            </Card>
        },
      ]
  },
  {
    routeName: "/Faq",
    isActive: false, title: "FAQs", icon: <ErrorOutlineOutlinedIcon />, key: 'FAQs', route: ["Faq"]
  },
  {
    routeName: "/AccountSettings",
    isActive: false, title: "Account Settings", icon: <PersonOutlineOutlinedIcon />, key: 'Account', route: ["AccountSettings"]
  },

  ]);
  const colourChange = () => {
    if (window.location.pathname == "/StudentEnrollmentFormStepper") {
      routes[0].isActive = true;
    }
    if (window.location.pathname == "/StudentDetails") {
      routes[2].isActive = true;
    }
    if (window.location.pathname == "/HomeschoolButtons" || window.location.pathname == "/HomeschoolProgram"
      || window.location.pathname == "/HomeschoolStyle" || window.location.pathname == "/HomeSchoolStyleRecommendationResult" || window.location.pathname == "/HomeSchoolProgramRecommendationResult"
    ) {
      routes[0].isActive = true;
    }
    if (window.location.pathname == "/OrientationVideo") {
      routes[1].isActive = true;
    }
    if (window.location.pathname == "/CreateTickets") {
      routes[3].isActive = true;
    }
    if (window.location.pathname == "/Chat9") {
      routes[3].isActive = true;
    }


  }


  colourChange();

  React.useEffect(() => {
    updateRoute()
  }, [])

  const { pathname } = props?.location;
  React.useEffect(() => {
    if (pathname == '/OrientationVideo') {
      let a = routes[1].subArray.map((obj: any, index: any) => {
        if (index == 0) {
          return { ...obj, isActive: true }
        }
        else {
          return obj
        }

      })
      setRoutes(routes.map((obj: any, index: any) => {
        if (index == 1) {
          return { ...obj, subArray: a }

        }
        else {
          return obj
        }

      }))

      setSubArrayState((prevState: any) => ({
        ...prevState,
        Overview: { keystate: true, }
      }))
    }
    if (pathname == '/Chat9') {
      let a = routes[3].subArray.map((obj: any, index: any) => {
        if (index == 0) {
          return { ...obj, isActive: true }
        }
        else {
          return obj
        }

      })
      setRoutes(routes.map((obj: any, index: any) => {
        if (index == 3) {
          return { ...obj, subArray: a }

        }
        else {
          return obj
        }

      }))


      setSubArrayState((prevState: any) => ({
        ...prevState,
        touch: { keystate: true, }
      }))
    }
    if (pathname == '/team') {
      setSubArrayState((prevState: any) => ({
        ...prevState,
        touch: { keystate: true, }
      }))
    }
    if (pathname == '/CreateTickets') {
      let a = routes[3].subArray.map((obj: any, index: any) => {
        if (index == 1) {
          return { ...obj, isActive: true }
        }
        else {
          return obj
        }

      })
      setRoutes(routes.map((obj: any, index: any) => {
        if (index == 3) {
          return { ...obj, subArray: a }

        }
        else {
          return obj
        }

      }))
      setSubArrayState((prevState: any) => ({
        ...prevState,
        touch: { keystate: true, }

      }))
    }



  }, [pathname])


  const updateRoute = () => {
    const { pathname } = props?.location;
    let emt: any = []
    routes.map((i: any, index: number) => {
      if (i.routeName == pathname || i.key == subArrayManage) {
        emt.push(Object.assign({ routeName: i.routeName, isActive: true, title: i.title, icon: i.icon, route: i.route, subArray: i.subArray && i.subArray.length != 0 ? i.subArray : [], key: i.key }))
      }
      else {
        emt.push(Object.assign({ routeName: i.routeName, isActive: false, title: i.title, icon: i.icon, route: i.route, subArray: i.subArray && i.subArray.length != 0 ? i.subArray : [], key: i.key }))
      }
    })
    setRoutes(emt)
  }
  const bottomTap = (parent: any) => {
    let emt: any = []
    let stateCheck: any = subArrayState

    stateCheck[parent.key].keystate && parent.subArray && parent.subArray.length != 0 &&
      parent.subArray.map((item: any, index: any) => {
        emt.push(
          <ListItem
            button
            onClick={() => {

              item.title == 'HG Formula' && localStorage.setItem('hglink', 'link1');
              item.title == 'Explore Programs' && localStorage.setItem('hglink', 'link2');
              item.title == 'Info Pack & E-book' && localStorage.setItem('hglink', 'link3');
              item.title == 'About HG' && localStorage.setItem('hglink', 'link4');
              props.navigation.navigate(String(item.routeName))
            }}
            className={classes.nested} style={{ paddingBottom: '0' }} key={item + index} >
            <ListItemIcon style={{ color: item.isActive ? '#99cb51' : 'gray' }} >
              <FiberManualRecordSharpIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={<span style={{
              fontSize: "14px", fontWeight: 300,
              color: item.isActive ? '#99cb51' : '#171725',
              fontFamily: "Poppins",
              paddingBottom: '0px',

            }}
            >{item.title}</span>} />


          </ListItem>

        )
      })
    return emt
  }
  
  const handleClickOpenLogOutModal = () => {
    setOpen(true);
  };

  const handleCloseLogOutModal = () => {
    setOpen(false);
  };


  const handleLogOut = () => {
    handleCloseDrop()
    handleClickOpenLogOutModal()
  }
  const logoutButtonHandler = () => {
      localStorage.removeItem('authToken')
      localStorage.removeItem('studentID')
      localStorage.removeItem('hglink')
      props.navigation.navigate('EmailAccountLoginBlock')
      handleCloseLogOutModal()
  }

  return (
    <div className={classes.root}>

      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {

        })}
      >
        <Toolbar style={{ justifyContent: 'space-between', backgroundColor: '#fff' }}>
          <Box style={{ width: '20%' }}>
            <img src={logo} alt='logo image' style={webStyle.imgLogoStyle} onClick={navigateTOHome} />
          </Box>
          <Box style={{ display: 'flex', alignItems: 'end', textAlign: 'end', justifyContent: 'space-between' }}>
            <Avatar alt="client avatar" src={avatar} style={webStyle.avatarStyle} onClick={handleClickDrop}/>
            <Typography style={{ color: '#000', marginBottom: "0.3em", cursor:'pointer' }} onClick={handleClickDrop}>{localStorage.getItem('username')}</Typography>

            <Button onClick={handleClickDrop} >
              <ArrowDropDownIcon style={{ color: '#92929d' }} />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseDrop}
              style={{ marginTop: '4em' }}
            >
              <Button onClick={handleLogOut} style={{ textTransform: 'none', width:'200px' }}>Logout</Button>
              <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <h2 className={classes.logoutMessage}>You are attempting to logout of HG Application.</h2>
                  <Typography className={classes.confirmationMessage}>Are you sure?</Typography>
                  <button className={classes.cancelButton} autoFocus onClick={handleCloseLogOutModal}>
                    X
                  </button>
                  <div style={{ textAlign: 'center', marginTop: '30px' }}>
                    <Button
                      variant="contained"
                      className={classes.logoutButton} onClick={logoutButtonHandler}>LOGOUT</Button>
                  </div>
                </Box>
              </Modal>
            </Menu>
          </Box>

        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: props?.openD,
          [classes.drawerClose]: !props?.openD,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: props?.openD,
            [classes.drawerClose]: !props?.openD,
          }),
        }}
      >
        <div className={classes.toolbar}  >
          <IconButton>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>Bar
        </div>

        <Divider />
        <div className={classes.size}>
        </div>

        {routes.map((parent: any, index: number) => (
          <Box key={parent + index}>
            <ListItem
              button
              onClick={() => {
                setSubArrayManage(parent.key);
                if (parent.title == "Home") {

                  props?.handleDrawerOpen();
                  setSubArrayState((prevState: any) => ({
                    ...prevState,
                    Overview: { keystate: false, },
                    touch: { keystate: false, }

                  }))

                  if (localStorage.getItem('complete') === 'true') {
                    props.navigation.navigate(String(parent.route[0]))
                  }
                  else {
                    props.navigation.navigate(String(parent.route[1]))
                  }
                }
                else {

                  if (parent.subArray && parent.subArray.length != 0) {
                    if (!props?.openD) {
                      props?.handleDrawerOpen();
                    }

                    let names = Object.keys(subArrayState)
                    let result = names.filter((word: any) => word == parent.key);
                    if (parent.key == result[0]) {
                      setSubArrayState((prevState: any) => ({
                        ...prevState,
                        [parent.key]: { ...prevState[parent.key], keystate: !prevState[parent.key].keystate, }
                      }))
                    }
                  } else {
                    props.navigation.navigate(String(parent.route[0]))
                  }
                }
              }
              }
              style={{ paddingTop: "1em", borderLeft: parent.isActive ? '0.4rem solid #99cb51' : '0rem solid' }} >
              <ListItemIcon style={{ marginLeft: parent.isActive ? '0rem' : '0.4rem', color: parent.isActive && props.openD ? "#99cb51" : "#171725", minWidth: '4em' }}>
                {parent?.icon}
              </ListItemIcon>

              <ListItemText primary={<span style={{
                fontSize: "14px", fontWeight: 500,
                color: parent.isActive ? "#99cb51" : "#171725",
                fontFamily: "Poppins"
              }}
              >
                <Box style={{ display: 'flex', position: 'relative'}}>
                  {parent?.title} <Box style={{  position: "absolute", marginLeft: '12.5em', marginTop: '-0.2em' }} >
                    {parent?.title == "Overview" &&
                      (subArrayState.Overview.keystate ? <ExpandLess /> : <ExpandMore />) 
                    }
                    {parent?.title == "Get in touch" &&
                      (subArrayState.touch.keystate ? <ExpandLess /> : <ExpandMore />) 
                    }
                  </Box>
                </Box>
              </span>} />
            </ListItem>

            {bottomTap(parent)}


          </Box>
        ))}
      </Drawer>
    </div>
  );
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '10px',

  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
  outline: "none",
};

export default withRouter(MiniDrawer)
const webStyle = {
  imgLogoStyle: {
    width: '100%',
    cursor: 'pointer',
  },
  avatarStyle: {
    width: '32px',
    height: '32px',
    marginRight: '10px',
    cursor:'pointer'
  }
}
