import React from "react";

import {
  // Customizable Area Start
  Container,
  Box,
  Button,
  Typography,
   Grid,
   TextField,
   InputLabel,
   MenuItem,
   FormControlLabel,
   Checkbox,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider,withStyles } from "@material-ui/core/styles";
import Drawer from "../../../components/src/Drawer.web";
export const configJSON = require("./config");
import TicketFileUpload from "../../../components/src/TicketFileUpload.web";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Autocomplete from '@material-ui/lab/Autocomplete';

const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
 });

const StyledTextField = withStyles({
  root: {
    fontFamily: 'Roboto',
    fontSize: '16',
    "& .MuiFilledInput-root": {
      backgroundColor: "transparent",
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: "transparent",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      }
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "transparent",
    },
    "& input::placeholder": {
      fontFamily: 'Roboto',
      fontSize: '16',
      fontWeight: 400,
      width: '222.98px',


    },
  }
})((props: any) => <TextField {...props} />);

// Customizable Area End
// Customizable Area Start
import TicketSystemController, {
  Props,
} from "./TicketSystemController";
 // Customizable Area End
export default class Tickets extends TicketSystemController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    const { editorState } = this.state;
     return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>

        <Container maxWidth={false}>

          <Grid container style={{ display: 'block' }}>
            {/* ---------------------- drawer ------------------------- */}
            <Grid item>

              <Drawer openD={this.state?.openD}
                handleDrawerOpen={this.handleDrawerOpen}
                navigation={this.props.navigation}
              />
            </Grid>


            {/* ---------------------- drawer Content ------------------------- */}
            <Grid item>
              <Container
                style={
                  this.state?.openD ?
                    {
                      marginLeft: `${this.state?.drawerWidth}px`,
                      maxWidth: `calc(100% - ${this.state?.drawerWidth}px)`,
                      padding: 0,
                    } :
                    { maxWidth: '98%', marginLeft: '2.5em' }
                }
                maxWidth={false}
              >

                {/* ---------------------- MAIN CONTENT OF FILE START ------------------------- */}

                <Container maxWidth={false} style={{ margin: '0 auto', width: '100%' }}>
                  <Box style={{ marginTop: '100px' }}>
                    <Typography className='welcomeText' style={webStyle.welcomeTextStyle} >Create New Ticket </Typography>
                  </Box>
                  <hr style={{ marginBottom: '1em', border: "1px solid #dcdcdc", width: '100%', backgroundColor: '#dcdcdc' }}></hr>

                  <Container maxWidth={false} style={webStyle.mainWrapper}>
                         <Container maxWidth={false} style={webStyle.GridWrapper}>
                              <Typography style={webStyle.mainheader}>Ticket Details</Typography>
                      

                      {/* ------------------------ inner contents --------------------------     */}
                      <Grid container style={{padding:'2em', display:'flex', flexDirection:'column'}}>

                      <Grid item xs={12} md={5}>
                        <Box style={webStyle.inputBoxStyle}>
                          <InputLabel style={webStyle.inputSelectLabelStyle}>{configJSON.ticketCategory} </InputLabel>
                          <StyledTextField
                            select
                            required
                            InputLabelProps={{ shrink: false }}
                            fullWidth={true}
                            name='ticketCategory'
                            value={this.state.ticketCategory.name}
                            onChange={this.handleChangeTicketCategory}
                          >
                             <MenuItem style={{ display: "none", color: '#ABABB0' }} value="choose"> <span style={{ color: "#ABABB0" }}>Select</span></MenuItem>
                             {
                                  this.state.ticketCategoryDataSelect.length > 0 && this.state.ticketCategoryDataSelect?.map((item: any) => {
                                  return (
                                  <MenuItem
                                    key={item?.id}
                                    value={item?.name}
                                    selected={item.value == this.state.ticketCategory.name}
                                  >
                                    {item?.name}
                                  </MenuItem>
                                  )
                                })

                              }
                            </StyledTextField>
                        </Box>
                        <Box>
                          {
                            (this.state.ticketErrors.ticketCategory)
                              ? <small style={webStyle.errorStyle}>{this.state.ticketErrors.ticketCategory}</small>
                              : <small style={{ visibility: 'hidden' }}>""</small>
                          }
                        </Box>
                      </Grid>
                      {/* ------------------------ Academic & Testing Conditional rendering starts ---------------------- */}
                     
                      {/* <Grid item xs={12} md={5}>
                      <Box style={webStyle.inputBoxStyle}>
                     <InputLabel style={webStyle.inputSelectLabelStyle}> Type of Concern* </InputLabel>
                   
                    <Autocomplete
                     value={this.state.academicProgramAutoCompleteValue}
                     onChange={(e:any, newValue:any)=>{this.setState({academicProgramAutoCompleteValue:newValue})}}
                     options={ this.state.academicConcernData}
                     getOptionLabel={(option:any) => option.text || ""}
                     getOptionSelected={(option, value) => option.value === value.value}
                     renderInput={(params) =>  < StyledTextField {...params} placeholder='Please Select' fullWidth={true} name='academicConcern'  InputLabelProps={{ shrink: false }} /> }
                     inputValue={this.state.academicConcern.text}
                     onInputChange={(e:any, newInputValue:any) => { this.handlerChangeacademicConcern(e,newInputValue) }}
                     
                        />
                    </Box>
                  
                      </Grid> */}
 {/* ------------------------ Academic & Testing Conditional rendering starts ---------------------- */}
                      <Grid item xs={12} md={5}>
                        <Box style={webStyle.inputBoxStyle}>
                          <InputLabel style={webStyle.inputSelectLabelStyle}>{configJSON.ticketSubject} </InputLabel>
                          <StyledTextField
                            placeholder="Type here"
                            required
                            fullWidth={true}
                            name='ticketSubject'
                            value={this.state.ticketSubject}
                            onChange={this.handlerChangeticketSubject}
                            onFocus={this.hideError}
                          >

                          </StyledTextField>
                        </Box>
                        <Box>

                          {
                            (this.state.ticketErrors.ticketSubject)
                              ? <small style={webStyle.errorStyle}>{this.state.ticketErrors.ticketSubject}</small>
                              : <small style={{ visibility: 'hidden' }}>""</small>
                          }
                        </Box>
                      </Grid>
                      <Grid item xs={12} >
                        <Box style={webStyle.inputBoxStyle}>
                        <InputLabel style={webStyle.inputSelectLabelStyle}>{configJSON.ticketMessage} </InputLabel>
                          <Box style={
                            {
                              borderRadius: '15px',
                              border: '1px solid #e2e2ea',
                            }
                          }>
                          <Editor
                                editorState={editorState}
                                editorStyle={webStyle.editorClassStyle}
                                onEditorStateChange={this.onEditorStateChange}
                          />
                         </Box>
                        </Box>
                        <Box>
                          {
                            (this.state.ticketErrors.editorState)
                              ? <small style={webStyle.errorStyle}>{this.state.ticketErrors.editorState}</small>
                              : <small style={{ visibility: 'hidden' }}>""</small>
                          }
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6} style={{display:'flex'}}>
                          <Button
                            style={{textTransform: 'none', ...webStyle.btnAddStyle}}
                             // onClick={}
                            >Add CC
                      </Button>
                          <Button
                            style={{textTransform: 'none', ...webStyle.btnAddStyle}}
                             // onClick={}
                            >Add BCC
                      </Button>
                      </Grid>
                      <Grid item xs={12} > 
                       <TicketFileUpload uploadFiles={this.uploadFiles}
                       ticketFile={this.state.ticketFile}
                        />
                      
                      <Grid item xs={12} md={5}>
                        <Box style={webStyle.inputBoxStyle}>
                          <InputLabel style={webStyle.headingContactStyle}>{configJSON.contactDetails} </InputLabel>
                         </Box>
                       </Grid>

                      <Grid item xs={12} md={5}>
                        <Box style={webStyle.inputBoxStyle}>
                          <InputLabel style={webStyle.inputSelectLabelStyle}>{configJSON.ticketFullName} </InputLabel>
                          <StyledTextField
                            placeholder="Type here"
                            required
                            fullWidth={true}
                            name='ticketFullName'
                            value={this.state.ticketFullName}
                            onChange={this.handlerChangeticketFullName}
                            onFocus={this.hideError}
                          >

                          </StyledTextField>
                        </Box>
                        <Box>

                          {
                            (this.state.ticketErrors.ticketFullName)
                              ? <small style={webStyle.errorStyle}>{this.state.ticketErrors.ticketFullName}</small>
                              : <small style={{ visibility: 'hidden' }}>""</small>
                          }
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Box style={webStyle.inputBoxStyle}>
                          <InputLabel style={webStyle.inputSelectLabelStyle}>{configJSON.ticketEmail} </InputLabel>
                          <StyledTextField
                            placeholder="Type here"
                            required
                            fullWidth={true}
                            name='ticketEmail'
                            value={this.state.ticketEmail}
                            onChange={this.handlerChangeticketEmail}
                            onFocus={this.hideError}
                          >

                          </StyledTextField>
                        </Box>
                        <Box>

                          {
                            (this.state.ticketErrors.ticketEmail)
                              ? <small style={webStyle.errorStyle}>{this.state.ticketErrors.ticketEmail}</small>
                              : <small style={{ visibility: 'hidden' }}>""</small>
                          }
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Box style={webStyle.inputBoxStyle}>
                          <InputLabel style={webStyle.inputSelectLabelStyle}>{configJSON.ticketPhoneNumber} </InputLabel>
                          <StyledTextField
                            placeholder="Type here"
                            required
                            fullWidth={true}
                            name='ticketPhoneNumber'
                            value={this.state.ticketPhoneNumber}
                            onChange={this.handlerChangeticketPhoneNumber}
                            onFocus={this.hideError}
                          >

                          </StyledTextField>
                        </Box>
                        <Box>

                          {
                            (this.state.ticketErrors.ticketPhoneNumber)
                              ? <small style={webStyle.errorStyle}>{this.state.ticketErrors.ticketPhoneNumber}</small>
                              : <small style={{ visibility: 'hidden' }}>""</small>
                          }
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                      <FormControlLabel style={webStyle.formlabelStyle}
                          control={
                            <Checkbox
                              style={{ color: '#757575'}}
                              size="small" 
                              checked={this.state.ticketConsent}
                              onChange={this.handlerChangeticketConsent}
                            />}
                          label={configJSON.ticketConsent}
                        />
                        <Box>
                          {
                            (this.state.ticketErrors.ticketConsent)
                              ? <small style={webStyle.errorStyle}>{this.state.ticketErrors.ticketConsent}</small>
                              : <small style={{ visibility: 'hidden' }}>""</small>
                          }
                        </Box>
                    </Grid>  

                     <Grid item xs={12} style={{ marginTop: '3em', textAlign: "right" }}>
                            <Button variant="contained" style={{ textAlign: "center", textTransform: "none", ...webStyle.btnTicketCancel }}
                            onClick={this.handleCancelTicket}
                            >Cancel
                            </Button>
                            <Button variant="contained" style={{ textAlign: "center", textTransform: "none", ...webStyle.btnTicketCreate }} 
                            onClick={this.handleCreateTicket}
                            > Create New Ticket 
                            </Button>
                      </Grid>




                    </Grid>                     
                    </Grid>                     
                    </Container>
                 </Container>


                </Container>

                {/* ---------------------- MAIN CONTENT OF FILE END ------------------------- */}

              </Container>
            </Grid>
          </Grid>
          


        </Container>
      </ThemeProvider >
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  welcomeTextStyle: {
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 600,
   },

   btnAddStyle: {
    width: '132px',
    height: '52px',
    borderRadius: '10px',
    backgroundColor:'#e4e4e4',
    border: "none",
    color: '#757575',
    fontFamily: 'Poppins',
    fontSize: ' 16px',
    fontWeight: 500,
    backgroundBlendMode: 'normal',
    margin:'1.5em 1.5em 1.5em 0',
  },
 
  btnTicketCancel: {
    width: "200px",
    height: "52px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
    color: "#fafafb",
    backgroundColor: "#b9b9b9",
    borderRadius: "10px",
     marginLeft: '1em',
  },
  btnTicketCreate: {
    width: "300px",
    height: "52px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
    color: "#fafafb",
    backgroundColor: "#fab03d",
    borderRadius: "10px",
     marginLeft: '1em',
  },
 
  mainWrapper: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'center',
    boxShadow: '3px -5px 40px rgba(205,205,212,0.1)',
    padding: '0em',
},
GridWrapper: {
  borderRadius: "15px",
  background: "#ffffff",
  border: "1px solid #f1f1f5",
  padding: "2em",
  marginTop: "2em",
},
mainheader: {
  color: " #171725",
  fontFamily: "Poppins",
  fontSize: "20px",
  fontWeight: 600,
},
inputBoxStyle: {
  marginTop: '30px',
},

inputSelectLabelStyle: {
  color: '#757575',
  fontFamily: 'Poppins',
  fontSize: '12px',
  fontWeight: 400,
  marginBottom: '5px',
},
headingContactStyle: {
  color: '#171725',
  fontFamily: 'Poppins',
  fontSize: '20px',
  fontWeight: 600,
  marginBottom: '1em',
},
errorStyle: {
  padding: '5px',
  color: 'red',
  fontFamily: 'Roboto',
  fontSize: ' 12px',
},
formlabelStyle:{ 
  display: 'block',
  marginTop: '2em',
  color: '#757575',
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: 300 
  },
editorClassStyle:{
  height: '250px',
  overflowy: 'auto',
  padding: '15px',
},

};
// Customizable Area End
