Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TicketSystem";
exports.labelBodyText = "TicketSystem Body";

exports.btnExampleTitle = "CLICK ME";

exports.ticketCategory = "Category*";
exports.ticketSubject = "Subject*";
exports.ticketMessage = "Message*";
exports.contactDetails = "Contact Details";
exports.ticketFullName = "Full Name*";
exports.ticketEmail = "Email*";
exports.ticketPhoneNumber = "Phone Number*";
exports.ticketConsent = "      I consent to collecting my personal information for your support team to communicate.";

exports.apicontentType = "application/json";
exports.apiGetMethod = "GET";
exports.apiPostMethod = "POST";

exports.apiTicketCategoriesCallUrl="bx_block_ticketsystem/ticket_systems/get_all_categories";
exports.apiCustomCategoriesCallUrl="bx_block_ticketsystem/ticket_systems/get_category_custom_fields";
exports.apiCreateTicketCallUrl="bx_block_ticketsystem/ticket_systems";


exports.contentTypeApiAllTickets = "application/json";
exports.allTicketsMethodType = "GET";
exports.allTicketsUrl="bx_block_ticketsystem/ticket_systems"
// Customizable Area End