


import React from "react";

import {
    // Customizable Area Start
    Container,
    Box,
    Button,
    Typography,
    Grid,
    Avatar
    // Customizable Area End
} from "@material-ui/core";



// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Drawer from "../../../components/src/Drawer.web";
import { avatar, rightArrowActive, rightArrowInActive, leftArrowActive, lefttArrowInActive } from './assets';
import { makeStyles } from '@material-ui/core/styles';
import "./TicketSystem.css"
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const theme = createTheme({

    palette: {
        primary: {
            main: "#nnn",
            contrastText: "#fff",
        },
    },
    typography: {

        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },

    overrides: {
        MuiInput: {
            underline: {

                " &:before": {
                    borderBottom: 'none',
                    transition: 'none',
                    content: 'none',
                },

            },
        },

    },
});


// Customizable Area End
// Customizable Area Start
import TicketSystemController, { Props, } from "./TicketSystemController";

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
});

const TicketTable = (props: any) => {
    const { allTicketsData, ticketsPageInfo } = props;
    // console.log("count", ticketsPageInfo.count)
    const perPage = ticketsPageInfo.count;
    const [rowsPerPage, setRowsPerPage] = React.useState(perPage);
    const [prevDisable, setPrevDisable] = React.useState(true);
    const [nextDisable, setNextDisable] = React.useState(false);
    const [value, setValue] = React.useState();

    const statusColor: any = { "completed": "#99CB51", "pending": "#EAC543", "Pending Action required": "#F4A760" }

    console.log("luck ticketsPageInfo", ticketsPageInfo);
    console.log("luck allTicketsData", allTicketsData);
    const rowsDataLength = ticketsPageInfo.last_index;
    // const noOfPages = Math.ceil(rowsDataLength / perPage);
    const prevPageValue = rowsPerPage - perPage;
    const nextPageValue = rowsPerPage > rowsDataLength ? rowsDataLength : rowsPerPage;

    const handlePrevious = () => {
        // console.log('lak handlePrevious rowsPerPage', rowsPerPage, "pageNum", pageNum);
        if (rowsPerPage >= 0) {
            setRowsPerPage(rowsPerPage - perPage);
            setPrevDisable(false);
            setNextDisable(false);
        } else {
            setPrevDisable(true);
        }
    }

    const handleNext = () => {
        if (rowsPerPage < rowsDataLength) {
            setRowsPerPage(rowsPerPage + perPage);
            setPrevDisable(false);
            setNextDisable(false);
        } else {
            setNextDisable(true);
        }
    }

    const handleChange = (e: any) => {
        setValue(e.target.value);
    };

    const prevDiabled = prevDisable || prevPageValue === 0
    const nextDisabled = nextDisable || rowsPerPage > rowsDataLength;


    return (
        <Box className="ticketBox">
            <Box className="boxHeader">
                <Box className="dropDown">
                    <select value={value} onChange={handleChange} style={webStyle.dropDownStyles}>
                        <option value="All Tickets">All Tickets</option>
                        <option value="Pending Tickets">Pending Tickets</option>
                        <option value="Completed Tickets">Completed Tickets</option>
                        <option value="Pending Action Required">Pending Action Required</option>
                    </select>

                </Box>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Button onClick={handlePrevious} disabled={prevDiabled}>
                        {prevDiabled ? <img src={lefttArrowInActive} alt="backwardArrow" /> : <img src={leftArrowActive} alt="backwardArrow" />}

                    </Button>
                    <Typography>{prevPageValue} - {nextPageValue}</Typography>
                    <Button onClick={handleNext} disabled={nextDisabled}>
                        {nextDisabled ? <img src={rightArrowInActive} alt="rightArrow" /> : <img src={rightArrowActive} alt="rightArrow" />}
                    </Button>
                </Box>
            </Box>
            <hr />
            <Box className="headerStyle">
                <Typography className="width30">Status</Typography>
                <Typography className="width30">Subject</Typography>
                <Typography className="width20">Submitted on</Typography>
                <Typography className="width20">Ticket Id</Typography>
            </Box>
            {/* {rowsData.slice(prevPageValue, nextPageValue).map((rowValue: any) => { */}
            {allTicketsData.map((rowValue: any) => {
                return (
                    <Box className="bodyStyle">
                        <Typography className="width30 statusBar" style={{ height: "auto", backgroundColor: statusColor[rowValue.status.behavior] }}>{rowValue.status.behavior}</Typography>
                        {/* <Typography className="width30 statusBar" style={{ height: "auto", backgroundColor: rowValue.status.color }}>{rowValue.status.behavior}</Typography> */}
                        <Typography className="width30 subjectName">{rowValue.subject}</Typography>
                        <Typography className="width20">{rowValue.created_at.split(' ')[0]}</Typography>
                        <Typography className="width20">{rowValue.id}</Typography>
                    </Box>
                )
            })}
        </Box>
    )
}



// Customizable Area End
export default class TicketStatusInfo extends TicketSystemController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>

                <Container maxWidth={false}>

                    <Grid container style={{ display: 'block' }}>
                        {/* ---------------------- drawer ------------------------- */}
                        <Grid item>

                            <Drawer openD={this.state?.openD}
                                handleDrawerOpen={() => this.setState({ openD: !this.state?.openD })}
                                navigation={this.props.navigation}
                            />
                        </Grid>


                        {/* ---------------------- drawer Content ------------------------- */}
                        <Grid item>
                            <Container
                                style={
                                    this.state?.openD ?
                                        {
                                            marginLeft: `${this.state?.drawerWidth}px`,
                                            maxWidth: `calc(100% - ${this.state?.drawerWidth}px)`,
                                            padding: 0,
                                        } :
                                        { maxWidth: '98%', marginLeft: '2.5em' }
                                }
                                maxWidth={false}
                            >

                                {/* ---------------------- MAIN CONTENT OF FILE START ------------------------- */}

                                <Container maxWidth={false} style={{ margin: '0 auto', width: '100%' }}>
                                    <Box style={{ marginTop: '100px', display: "flex", justifyContent: "space-between" }}>
                                        <Typography className='welcomeText' style={webStyle.welcomeTextStyle} >Tickets </Typography>
                                        <Button variant="contained" style={{ textAlign: "center", textTransform: "none", ...webStyle.btnTicketCreate }}  onClick={this.handleCreateTicket}>                                                                                
                                         New Ticket
                                        </Button>
                                    </Box>
                                    {/* <hr style={{ marginBottom: '1em', border: "1px solid #dcdcdc", width: '100%', backgroundColor: '#dcdcdc' }}></hr> */}
                                    {this.state.allTicketsData.length > 0 &&
                                        <TicketTable allTicketsData={this.state.allTicketsData} ticketsPageInfo={this.state.ticketsPageInfo} />
                                    }


                                </Container>

                                {/* ---------------------- MAIN CONTENT OF FILE END ------------------------- */}

                            </Container>
                        </Grid>
                    </Grid>


                </Container>
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyle = {

    btnTicketCreate: {
        width: "235px",
        height: "52px",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 600,
        color: "#fafafb",
        backgroundColor: "#fab03d",
        borderRadius: "10px",
        marginLeft: '1em',
    },
    welcomeTextStyle: {
        //marginTop: '50px',
        // color: '#171725',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: 600,
        display: "flex",
        alignItems: "center"
    },

    btnStatus: {
        width: '200px',
        height: '24px',
        borderRadius: '10px',
        border: "none",
        color: '#fafafb',
        fontFamily: 'Poppins',
        fontSize: ' 11px',
        fontWeight: 500,
        backgroundBlendMode: 'normal',
        padding: '1.2em 0.5em'
    },

    gridContentContainerStyle: {
        marginTop: '14px',
        border: '1px solid #f1f1f5',
        borderRadius: '15px',
        backgroundColor: '#ffffff',
        boxShadow: '0px 5px 15px rgba(68,68,79,0.1)',
        padding: '20px',
        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'column' as 'column',
        justifyContent: 'space-between'


    },
    textTicketStyle: {
        height: '36px',
        color: '#171725',
        fontFamily: '  Poppins',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '36px',
    },
    headingTextStyle: {
        height: '18px',
        color: '#92929d',
        fontFamily: '  Poppins',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '18px',
    },

    headingPinTextStyle: {
        height: '21px',
        color: '#171725',
        fontFamily: '  Poppins',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '21px',
    },
    headingStatusTextStyle: {

        color: '#383838',
        fontFamily: 'Roboto',
        fontSize: '15px',
        fontWeight: 400,

    },
    avatarTitleTextStyle: {
        height: '14px',
        color: ' #92929d',
        fontFamily: '   Roboto',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '14px',
        marginBottom: '0.6em'
    },
    cardAvatarStyle: {
        width: '24px',
        height: '24px',
        marginRight: '10px'
    },
    dropDownStyles: {
        color: " #171725",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 500,
        width: "80%",
        border: "white"
    }

};

function useState(second: any) {
    throw new Error("Function not implemented.");
}
// Customizable Area End
